@import "../../variables.scss";

.page-footer {
    width: 100%;
    max-width: 1790px;
    margin: 3em auto 0;
    position: relative;
    padding: 2em 0 1em      ;

    border-top: 1px solid $color1;

    &:after {
        background: $color1;
        content: "";
        display: block;
        height: 1em;
        width: 50.7%;
        position: absolute;
        left: 0;
        top: -1em;
    }

    .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        max-width: 525px;
        width: 85%;
        margin: 0 auto;
    }

    .wawa {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img {
            padding: 0 .5em;
        }
    }

    .gde {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        img {
            padding: 0 .5em;
        }
    }

    @media (max-width: 900px) {
        &:after {
            width: 100%;
        }
    }

    @media (max-width: 500px) {
        .wrapper {
            flex-direction: column;
            align-items: center;

            .wawa,
            .gde {
                margin: 10px 0;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
