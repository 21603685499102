@import "../../../variables.scss";

.class-raport-page {
    .content {
        width: 95%;
        max-width: 1750px;
        margin: 0 auto;
        position: relative;
    }

    .step {
        margin: 2em 0;
    }

    .step-info {
        display: flex;
        flex-direction: column;

        p {
            font-size: 1.2em;
            flex: 1 1 100%;
        }
    }

    .step-result {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        width: 80%;
        padding-left: 8%;
        margin-left: 2%;

        padding-bottom: 5px;
        border-bottom: 1px solid $color1;
        border-left: 5px solid $color1;

        flex: 1 1 auto;

        .stat {
            flex: 0 0 50%;
            display: flex;
            padding: 0.5em 0;
            flex-wrap: wrap;

            .text {
                padding-right: 0.5em;
            }
            .value {
                font-weight: bold;
            }
        }
    }


    h3 {
        margin-left: 2%;
    }

    .actions {
        margin-left: 2%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .action {
            flex: 0 0 30%;
        }

        .action-result {
            width: 80%;
            padding-left: 5%;
            margin-left: 5%;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            padding-bottom: 5px;
            border-bottom: 1px solid $color1;
            border-left: 5px solid $color1;
        }

        .stat {
            flex: 1 0 50%;
            display: flex;
            padding: 0.5em 0;
            flex-wrap: wrap;

            .stat-values {
                display: flex;
            }

            .value {
                font-weight: bold;
            }

            .proc,
            .value {
                padding: 0 0.3em;
            }
        }
    }
}
