@import '../../variables.scss';

.logged-info {

    padding-left: 1em;

    border-left: 3px solid $color1;

    display: flex;
    flex-direction: row;

    align-items: center;

    .user-name {
        display: inline-block;
        margin-right: 1em;
    }
}
