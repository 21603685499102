body.high-contrast-mode {
    background: black !important;
    color: #ffff03 !important;

    * {
        background-color: transparent !important;
        background-image: none;
        color: #ffff03 !important;
        border-color: #ffff03;
    }

    .accessibility-bar {
        position: relative;
        border-bottom: 1px solid #ffff03;
    }

    .top-gfx {
        display: none;
    }

    .page-footer:after {
        background-color: #ffff03;
    }
}
