.accessibility-bar {
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
    background: white;

    padding: 18px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    z-index: 1;

    .bar-container {
        max-width: 1920px;
        width: 95%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        flex-wrap: wrap;
    }

    .menus-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        flex-wrap: wrap;
    }

    .accessibility-menu {
        display: flex;
        flex-direction: row;

        align-items: baseline;

        line-height: 2rem;
    }

    .contast-menu {
        align-items: center;
        padding-left: 67px;

        .contrast-button {
            margin: 0 4px;
        }

        button {
            width: 2.2rem;
            height: 2.2rem;

            border-radius: 50%;
            border: 3px solid black;
            background: white;
            font-weight: bold;

            cursor: pointer;
            text-align: center;

            &.high-contrast {
                border-color: #ffff03;
                color: #ffff03;
                background: black;
            }
        }
    }

    .font-size-menu {
        padding-left: 50px;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        margin: 0 8px;

        align-items: baseline;
    }

    .font-button {
        background: none;
        border: none;

        display: inline-block;
        padding: 0 0.5rem;
        font-family: "Raleway", sans-serif;
        font-weight: 700;

        margin: 0 2px;
        text-align: center;

        cursor: pointer;

        &.font-100 {
        }

        &.font-150 {
            font-size: 120%;
        }

        &.font-200 {
            font-size: 140%;
        }
    }

    .page-controls {
        display: flex;
        flex-direction: row;

        nav {
            margin-right: 1em;
        }

        .user-name {
            text-overflow: ellipsis;
        }
    }

    @media (max-width: 1000px) {
        .bar-container {
            flex-direction: column;
        }
    }

    @media (max-width: 600px) {
        .contast-menu {
            padding-left: 0;
        }
    }

    @media (max-width: 500px) {
        .menus-container {
            flex-direction: column;
        }

        .accessibility-menu {
            margin: 15px auto;

            flex-wrap: wrap;
            justify-content: center;
        }

        .page-controls {

            flex-direction: column;
            align-items: center;

            .logged-info {
                margin-top: .5em;
                border: none;
            }
        }
    }
}
