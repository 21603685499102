@import "../../variables.scss";

.step-rules {
    flex: 0 0 45.5%;
    font-family: "Raleway", sans-serif;

    header {
        width: 100%;

        display: flex;
        justify-content: flex-end;

        .step-heading {
            color: black;
            font-size: 1.375rem;

            line-height: 2.75rem;
            margin: 0 0 10px 0;

            padding-left: 3.75rem;
            background: url("../../assets/icons/icon-data.svg") 0 50% no-repeat;
            background-size: auto 100%;

            .high-contrast-mode & {
                background-image: url("../../assets/icons/contrast/icon-data.svg");
            }
        }
    }

    .step-text {
        font-weight: bold;
        font-size: 1.2rem;
    }

    .textareas {
        margin-bottom: 30px;
    }

    textarea {
        border: 3px solid $color1;
        border-radius: 24px;

        padding: 26px;
        height: 5.5em;
        width: 85.5%;
        margin: 0.9em 0;

        font-family: Lato;
        font-size: 1rem;

        .high-contrast-mode & {
            border-color: #ffff03;
        }
    }

    .button {
        width: 290px;
    }

    .error-container {
        color: #B2182B;
        font-weight: bold;
    }

    @media (max-width: 900px) {
        flex-direction: column;
        align-items: stretch;

        padding-top: 15px;

        header {
            flex-direction: column;
            align-items: flex-start;

            h1 {
                margin-bottom: 15px;
            }
        }

        .step-text {
            margin: 20px auto;
        }

        .button {
            width: 100%;
        }
    }
}
