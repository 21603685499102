@import '../../variables.scss';

.audio-player {

    font-size: 1.2rem;

    display: flex;
    flex-direction: row;
    justify-content: center;

    .buttons {
        flex: 0 1 20%;

        display: flex;
    }

    .time-controlls {
        height: 1em;
        position: relative;
        flex: 0 1 80%;

        input {
            width: 100%;
            height: 100%;
            opacity: 0;
        }

        .axis {
            height: 0.2em;
            position: absolute;
            background: pink;

            width: 100%;
            top: 0.4em;
            bottom: 0.4em;

            pointer-events: none;
        }

        .marker {
            pointer-events: none;
            position: absolute;
            top: 0;
            bottom: 0;

            background: pink;

            left: 0;
            width: 5px;
        }
    }

    .timeinfo {
        flex: 0 1 60%;

        display: flex;

    }

    .label {
        flex: 0 1 20%;
        text-align: center;
    }

    button {
        background: url('../../assets/icons/play.svg') 0 50% no-repeat;
        background-size: auto 100%;

        text-decoration: underline;
        border: none;

        color: $color1;

        font-size: 1rem;
        font-weight: bold;
        padding-left: 3.5rem;
        cursor: pointer;
        line-height: 2.75rem;

        &.pause {
            background-image: url('../../assets/icons/pause.svg');
        }
    }

    .high-contrast-mode & {
        button {
            background-image: url('../../assets/icons/contrast/play.svg');

            &.pause {
                background-image: url('../../assets/icons/contrast/pause.svg');
            }
        }
    }
}
