.class-panel-page {
    .content {
        width: 95%;
        max-width: 1750px;
        margin: 0 auto;
        position: relative;
    }

    .links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 1em auto;

        & > * {
            margin: 0 1em;
        }

        @media (max-width: 900px) {
            flex-direction: column;
        }
    }
}
