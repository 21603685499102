@import "../../variables.scss";

.step-default {
    flex: 0 0 45.15%;

    header {
        width: 100%;

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .step-heading {
            color: black;
            font-size: 1.375rem;

            line-height: 2.75rem;
            margin: 0 0 10px 0;

            padding-left: 3.75rem;
            background: url("../../assets/icons/icon-data.svg") 0 50% no-repeat;
            background-size: auto 100%;

            .high-contrast-mode & {
                background-image: url("../../assets/icons/contrast/icon-data.svg");
            }
        }
    }

    .step-text {
        font-size: 1.15rem;

        margin: 42px auto 50px;
    }

    .task-actions {
        margin-bottom: 55px;
    }

    .action {
        margin: 40px 0;
        user-select: none;

        .action-radio {
            display: flex;
            align-items: center;
            user-select: none;

            transition: 0.2s;

            span {
                display: inline-block;
                background: $color1;
                padding: 20px;
                color: white;
                font-size: 2rem;
                font-weight: bold;
                border-radius: 20px;
                height: 1.75rem;
                width: 1.75rem;
                line-height: 1.75rem;
                text-align: center;
            }

            border: 3px solid white;
            border-radius: 15px;

            cursor: pointer;

            p {
                padding-left: 40px;
            }
        }

        input {
            opacity: 0;
            position: absolute;
            pointer-events: none;
        }

        input:focus + .action-radio,
        .action-radio:hover {
            border: 3px solid $color1;

            &:before {
                background: none;
                color: black;
            }
        }

        input:checked + .action-radio {
            background: $color1;
            border-color: $color1;
            color: white;

            &:before {
                background: none;
                color: white;
            }
        }

        .high-contrast-mode & {
            .action-radio {
                border-color: #ffff03;

                p {
                    background: transparent !important;
                }

                &:before {
                    background: none;
                    color: #ffff03;
                }
            }

            input:focus + .action-radio,
            .action-radio:hover {
                border-color: #ffff03;

                p {
                    background: transparent !important;
                }

                &:before {
                    background: none;
                    color: #ffff03;
                }
            }

            input:checked + .action-radio {
                background: #ffff03 !important;
                border-color: #ffff03 !important;
                color: black !important;

                p {
                    background: transparent !important;
                    color: black !important;
                }

                &:before {
                    background: none;
                    color: black !important;
                }
            }
        }
    }

    .button {
        width: 290px;
    }

    @media (max-width: 900px) {
        flex-direction: column;
        align-items: stretch;

        padding-top: 15px;

        header {
            flex-direction: column;
            align-items: flex-start;

            h1 {
                margin-bottom: 15px;
            }
        }

        .step-text {
            margin: 20px auto;
        }

        .button {
            width: 100%;
        }
    }
}
